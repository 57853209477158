import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import LegacyVerify, {
  VerifyAttachProps as LegacyVerifyAttachProps,
  VerifyCallbacks as LegacyVerifyCallbacks,
} from '@olo/auth-verify';
import {
  CreateAccountCheckbox,
  WorkflowModal,
  WorkflowModalAttachProps,
  VerifyCallbacks,
  VerifyAccountOverlay,
} from '@olo/borderless-authentication';

import ENV from 'mobile-web/config/environment';
import { host, nativeFetch } from 'mobile-web/lib/hybrid-util';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import WindowService from 'mobile-web/services/window';

import AnalyticsService, { AnalyticsEvents, AnalyticsProperties } from './analytics';
import BasketService from './basket';

type CreateAccountCheckboxProps = ConstructorParameters<typeof CreateAccountCheckbox>[0];

export default class OloAuthService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service channel!: ChannelService;
  @service basket!: BasketService;
  @service features!: FeaturesService;
  @service session!: SessionService;
  @service window!: WindowService;
  @service storage!: StorageService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  verify: VerifyAccountOverlay;
  legacyVerify: LegacyVerify;
  linkingVerify: WorkflowModal;

  // Tracked properties
  @tracked userVerified = false;

  private checkbox: CreateAccountCheckbox | undefined;

  // Getters and setters

  get createOloAccount(): boolean {
    return this.storage.createOloAccount ?? false;
  }

  set createOloAccount(checked: boolean) {
    if (this.checkbox) {
      this.checkbox.checked = checked;
    }
    this.updateCreateOloAccount(checked);
  }

  private updateCreateOloAccount(checked: boolean) {
    this.storage.createOloAccount = checked;
    this.analytics.trackEvent(
      AnalyticsEvents.OloAuthOptInChecked,
      () => ({ [AnalyticsProperties.Checked]: checked }),
      {
        bucket: 'all',
      }
    );
  }

  /**
   * Returns a url to the Olo Auth channel specific Manage Account page
   */
  get channelManageAccountUrl(): string {
    return `${this.bootstrap?.data?.oloAuthApiUrl}/Manage?olo-channel-id=${this.bootstrap?.data?.channel.guid}`;
  }

  // Lifecycle methods
  constructor() {
    super(...arguments);
    this.verify = new VerifyAccountOverlay();
    this.legacyVerify = new LegacyVerify();
    this.linkingVerify = new WorkflowModal();
  }

  // Other methods
  public async attachEmailListener({
    callbacks,
    ...props
  }: Pick<LegacyVerifyAttachProps, 'el' | 'initialValue' | 'callbacks'>) {
    const apiUrl = `${this.bootstrap.data?.oloAuthApiUrl}/api`;
    const channelId = this.channel.current?.guid;
    const loginProviderSlug = this.session.oloAuthProviderSlug;

    let loginUrl = '/login/otp';
    if (loginUrl[0] === '/') {
      const urlBase = host() || `${window.location.protocol}//${window.location.hostname}`;
      loginUrl = `${urlBase}${loginUrl}`;
    }

    this.legacyVerify.attachEmailListener({
      callbacks: {
        ...callbacks,
        onVerifyDone: this.createVerifyDoneCallback(callbacks?.onVerifyDone),
      },
      ...props,
      apiUrl,
      channelId,
      loginProviderSlug,
      loginUrl,
      fetchMethod: ENV.isHybrid ? nativeFetch : undefined,
    });
  }

  public async attachVerifyListener({
    callbacks,
    initialValue,
    emailEl,
    phoneEl,
  }: {
    callbacks: VerifyCallbacks;
    initialValue: { email: string; phone: string };
    emailEl: HTMLElement;
    phoneEl: HTMLElement;
  }) {
    const apiUrl = `${this.bootstrap.data?.oloAuthApiUrl}/api`;
    const channelId = this.channel.current?.guid;
    const loginProviderSlug = this.session.oloAuthProviderSlug;

    let loginUrl = '/login/otp';
    if (loginUrl[0] === '/') {
      const urlBase = host() || `${window.location.protocol}//${window.location.hostname}`;
      loginUrl = `${urlBase}${loginUrl}`;
    }

    this.verify.attachEmailListener({
      mode: 'dual',
      callbacks: {
        ...callbacks,
        onVerifyDone: this.createVerifyDoneCallback(callbacks?.onVerifyDone),
      },
      initialValue,
      emailEl,
      phoneEl,
      apiUrl,
      channelId,
      loginProviderSlug,
      loginUrl,
      fetchMethod: ENV.isHybrid ? nativeFetch : undefined,
    });
  }

  public detachEmailListener() {
    this.legacyVerify.detachEmailListener();
  }

  public attachLinkingListener({
    toggleEl,
    callbacks,
    modalTextOverrides,
  }: Pick<WorkflowModalAttachProps, 'toggleEl' | 'callbacks' | 'modalTextOverrides'>) {
    const apiUrl = `${this.bootstrap.data?.oloAuthApiUrl}/api`;
    const channelId = this.channel.current?.guid ?? '';
    const currentUser = this.session.currentUser;

    if (!currentUser) {
      return;
    }

    let loginUrl = '/login/otp';
    if (loginUrl[0] === '/') {
      const urlBase = host() || `${window.location.protocol}//${window.location.hostname}`;
      loginUrl = `${urlBase}${loginUrl}`;
    }

    this.linkingVerify.attach({
      email: currentUser.emailAddress,
      apiUrl,
      channelId,
      useSeparatedInputs: true,
      modalTextOverrides,
      callbacks,
      fetchMethod: ENV.isHybrid ? nativeFetch : undefined,
      loginUrl,
      toggleEl,
    });
  }

  public detachLinkingListener() {
    this.linkingVerify.modal?.detach();
  }

  public async checkEmailAddressExists(email: string) {
    const url = `${this.bootstrap.data?.oloAuthApiUrl}/api/user/check`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    return response.ok;
  }

  private createVerifyDoneCallback(onVerifyDone: LegacyVerifyCallbacks['onVerifyDone']) {
    return () => {
      this.userVerified = true;
      onVerifyDone?.();
      this.window.location().reload();
    };
  }

  public attachCreateAccountCheckbox({
    el,
    isLoyalty,
    onChecked,
  }: Pick<CreateAccountCheckboxProps, 'isLoyalty' | 'onChecked'> & { el: Element }) {
    const oloAuthOptinCheckboxUncheckedFeatureFlag =
      this.features.flags['abtest-oloauth-checkbox-unchecked-olo-102630'];

    this.createOloAccount = !oloAuthOptinCheckboxUncheckedFeatureFlag && true;

    const defaultCallback = (optedIn: boolean) => {
      this.updateCreateOloAccount(optedIn);
    };
    const checkInCallback = onChecked ?? defaultCallback;

    const checked = this.createOloAccount;
    //!oloAuthOptinCheckboxUncheckedFeatureFlag && this.createOloAccount;

    this.checkbox = new CreateAccountCheckbox({
      basketId: this.basket.basket?.id ?? '',
      apiUrl: `${this.bootstrap.data?.oloAuthApiUrl}/api`,
      clientId: this.channel?.current?.internalName ?? '',
      isLoyalty,
      brandPrivacyPolicyUrl: this.bootstrap.data?.customLinks?.find(
        l => l.linkLabelText === 'Privacy Policy'
      )?.url,
      onChecked: checkInCallback,
    });

    this.checkbox.checked = checked;

    this.checkbox.attach(el, 'inside');
  }

  public async oloAuthLinkabilityStatus() {
    const unlinked = this.session.unlinkedLoginProviders.find(lp => lp.isOloAuth);

    if (unlinked !== undefined) {
      return true;
    } else if (this.session.currentUser) {
      return await this.checkEmailAddressExists(this.session.currentUser.emailAddress);
    }

    return false;
  }
}

declare module '@ember/service' {
  interface Registry {
    'olo-auth': OloAuthService;
  }
}
